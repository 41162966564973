import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

const Body = () => (
  <div>
    <Container fluid>
      <Row>
        <Col className="px-0">
          <div className="banner" style={{ backgroundImage: 'url(/images/lilicats-banniere.jpg)' }}>
            <div className="banner-content">
              <h1 className="primary-heading">Garde de chats à&nbsp;votre&nbsp;domicile</h1>
              <h2 className="secondary-heading">
                La Londe-les-Maures & alentours (83)
              </h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="px-0" md="6">
          <div className="bloc-content bloc-content-primary">
            <h3 className="primary-heading mb-4">Qui suis-je ?</h3>
            <p className="content px-3 px-md-5">
              Titulaire de l&apos;ACACED (Attestation de connaissances
              pour les animaux de compagnie d&apos;espèces domestiques)
              et amoureuse des chats,
              j&apos;aime plus que tout m&apos;occuper d&apos;eux et les comprendre.
              Après des années au Canada, je reviens dans ma région natale et démarre
              mon activité de catsitting. En 2021, je me lance
              dans la vente en ligne avec
              {' '}
              <a className="link" href="https://nekolie.fr" target="_blank" rel="noreferrer">nekolie.fr</a>
              {' '}
              et l&apos;envie d&apos;offrir le meilleur à nos petits compagnons félins.
              Adepte du trail running et de photographie, j&apos;aime
              passer du temps sur les chemins de nature et derrière
              mon objectif quand je ne suis pas auprès de vos chats !
              <br />
              <br />
              Aurélie &#128571;
            </p>
          </div>
        </Col>
        <Col className="px-0" md="6">
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats-aurelie.jpg)' }} />
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <div className="bloc-content bloc-content-secondary">
            <h3 className="primary-heading mb-3">Prestation</h3>
            <p className="content px-3 px-md-5">
              Choisissez la formule de garde idéale pour votre chat :
              {' '}
              <strong>une visite</strong>
              {' '}
              ou
              {' '}
              <strong>deux visites</strong>
              {' '}
              par jour.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="px-0" md="6">
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats-1.jpg)' }}>
            <div>
              <h2 className="primary-heading heading-with-bg">Formules</h2>
            </div>
          </div>
        </Col>
        <Col className="px-0" md="6">
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats-tarifs-visites_ah.jpg)' }} />
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <div className="bloc-content bloc-content-secondary">
            <h3 className="primary-heading mb-3">Disponibilité</h3>
            <p className="content px-3 px-md-5">
              Les visites sont assurées 7 j/7.
              Les
              {' '}
              <strong>dimanches</strong>
              {' '}
              et
              {' '}
              <strong>les jours fériés</strong>
              {' '}
              seulement
              {' '}
              <strong>une visite</strong>
              {' '}
              par jour
              {' '}
              est possible (sauf cas particulier).
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="px-0" md="6">
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats-frais-kilometriques_ab.jpg)' }} />
        </Col>
        <Col className="px-0" md="6" xs={{ order: 'first' }} lg={{ order: 'last' }}>
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats-3.jpg)' }}>
            <div>
              <h2 className="primary-heading heading-with-bg">Frais de déplacement</h2>
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col className="px-0">
          <div className="bloc-content bloc-content-secondary">
            <h3 className="primary-heading mb-3">Le petit + Lilicats</h3>
            <p className="content px-3 px-md-5">
              Votre félin est une vraie boule d&apos;énergie quand vous rentrez du travail ?
              Ou au contraire, rien ne l&apos;amuse ?
              <br />
              Offrez-lui des
              {' '}
              <strong>séances de jeu</strong>
              {' '}
              avec Lilicats !
              J&apos;apporte avec moi jouets et accessoires qui suciterons
              l&apos;intêret de votre animal.
              <br />
              En jouant, le chat décharge une partie de ses émotions et vide ses batteries
              ce qui améliore son comportement en général.
              C&apos;est aussi un moyen efficace de le maintenir en bonne forme physique !
              Et cela, à tous les âges !
            </p>
          </div>
        </Col>
      </Row> */}
      {/* <Row>
        <Col className="px-0" md="6">
          <div className="img-bloc"
          style={{ backgroundImage: 'url(/images/lilicats-tarifs-visitesjeu_ac.jpg)' }} />
        </Col>
        <Col className="px-0" md="6" xs={{ order: 'first' }} lg={{ order: 'last' }}>
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats.jpg)' }}>
            <div>
              <h2 className="primary-heading heading-with-bg">Tarifs</h2>
            </div>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col className="px-0" md="6">
          <div className="bloc-content bloc-content-primary">
            {/* <h3 className="primary-heading mb-4">Frais supplémentaires</h3> */}
            <ul className="content px-3 px-md-5">
              <li>
                <span className="list-item-title">Remise et restitution des clés</span>
                12&nbsp;€
                {' '}
                <br />
                <span className="text-underline">Offertes</span>
                {' '}
                si les déplacements sont faits par vos&nbsp;soins !
                <br />
              </li>
              <li>
                <span className="list-item-title">Pour une garde de 3 chats ou plus</span>
                3&nbsp;€ / chat / visite
                <br />
              </li>
              <li>
                <span className="list-item-title">Horaire de visite spécifique</span>
                3&nbsp;€ / visite
                <br />
              </li>
              <li>
                <span className="list-item-title">
                  Jours fériés &#65121;
                  <br />
                </span>
                supplément de 50 % sur le tarif de base (hors barème kilométrique)
                <br />
              </li>
              <li>
                <span className="list-item-title">Réapprovisionnement d&apos;appoint</span>
                10&nbsp;€ + montant des achats
                <br />
              </li>
              <li>
                <span className="list-item-title">Déplacement vétérinaire</span>
                25&nbsp;€ + montant des honoraires
                <br />
              </li>
              <span className="list-item-title-light">
                &#65121; jour de l&apos;an, lundi de pâques, fête du travail,
                victoire&nbsp;1945, ascension, pentecôte, fête nationale,
                assomption, toussaint,
                armistice&nbsp;1918, jour de noël
              </span>
            </ul>
          </div>
        </Col>
        <Col className="px-0" md="6" xs={{ order: 'first' }} lg={{ order: 'last' }}>
          <div className="img-bloc" style={{ backgroundImage: 'url(/images/lilicats.jpg)' }}>
            <div>
              <h2 className="primary-heading heading-with-bg">Les à côtés</h2>
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <div className="eshop-banner-section" />
        </Col>
      </Row> */}
      {/* <Row>
        <Col className="px-0">
          <div className="bloc-content bloc-content-secondary">
            <h3 className="primary-heading mb-3">Tarif dégressif</h3>
            <p className="content px-3 px-md-5">
              Profitez d'une
              {' '}
              <strong>remise de 15 % </strong>
              à partir du 4ème jour de visite pour la formule à
              {' '}
              <strong>deux visites par jour</strong>
              .
            </p>
          </div>
        </Col>
      </Row> */}
    </Container>
  </div>

);

export default Body;
